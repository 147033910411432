<template>
	<h1>Form Text Input</h1>
	<div class="text-block bump-b-30">
		<pre v-highlightjs><code class="language-html">{{html}}</code></pre>
		<pre v-highlightjs><code class="language-javascript">import FormText from '@/components/FormText'

export default {
	components: { FormText },
	data() {
		return {
			name: ''
		}
	}
}
</code></pre>
	</div>
	<div class="text-block bump-b-30">
		<h3>Demo</h3>
		<p>
			<a href="#" @click.prevent="setErrors">Click here</a> to trigger error state. Edit the text fields to
			remove them.
		</p>
		<ul>
			<li>
				Name: <em>{{ name }}</em
				><br />
			</li>
			<li>
				Email: <em>{{ email }}</em
				><br />
			</li>
			<li>
				Password: <em>{{ password }}</em>
			</li>
		</ul>
		<br />
		<form id="form" class="structured-form" novalidate>
			<FormText
				v-model="name"
				label="Name"
				autocomplete="username"
				:error="errors.name"
				:autoFocus="true"
				@update="resetError('name')"
			/>
			<FormText
				v-model="email"
				type="email"
				label="Email"
				autocomplete="email"
				:error="errors.email"
				@update="resetError('email')"
			/>
			<FormText
				v-model="password"
				type="password"
				label="Password"
				autocomplete="current-password"
				:error="errors.password"
				@update="resetError('password')"
			/>
			<div class="empty-slot"></div>

			<p class="error-msg small">{{ errors.general }}</p>
		</form>
		<p>Input without a label:</p>
		<FormText placeholder="Name" />
	</div>
</template>

<script>
import FormText from '@/components/FormText'
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'DocFormsText',
	components: { FormText },
	setup() {
		injectMetaData({
			title: 'Doc: Forms › Text',
		})
	},
	data() {
		return {
			name: 'Arthur',
			email: 'hello@arthur.io',
			password: '',
			errors: {
				name: null,
				email: null,
				password: null,
			},
			test: false,
			html: [
				'<FormText',
				'	v-model="name"					// Data model',
				'	type="email"					// text, email, password (default text)',
				'	label="Name"					// If not set, no label will be rendered',
				'	placeholder="Name"				// If null, label value is used instead',
				'	autocomplete="username"			// See https://tinyurl.com/3cwfevvk',
				'	:error="errors.name"			// Set dynamic error message',
				'	:autoFocus="true"				// Focus on page load',
				'	@update="resetError(\'name\')"	// Reset error state on edit',
				'	@blur="doSomething()"			// Blur event',
				'',
				'									// Shared:',
				'	:mT/mR/mB/mL="30"				// Set single margin',
				'	:margin="[10,10,10,10]"			// Set all margins at once',
				'	:width="300"					// Default width is 250',
				'/>',
			].join('\n'),
		}
	},
	methods: {
		setErrors() {
			this.errors.name = 'Required'
			this.errors.email = 'Required'
			this.errors.password = 'Required'
			this.errors.general = "Something's wrong."
		},
		resetError(fieldName) {
			this.errors[fieldName] = ''
			this.errors.general = ''
		},
	},
}
</script>

<style scoped lang="scss">
#form {
	border: solid 0.01rem $black-10;
	padding: 0.4rem;
	padding-bottom: 0.3rem;
	margin-bottom: 0.2rem;
	border-radius: $br;
}
</style>
